import { SET_SKU, SET_ASIN, SET_SHIPPING, SET_ALERT,
         SET_IS_ASIN_VALID, SET_IS_ASIN_LOADING, SET_IS_SKU_VALID, SET_IS_URL_VALID, SET_URL, SET_TEMPLATES,
         SET_SUPPLIER_ITEM, TOGGLE_LOADING, SET_TEMPLATE, SET_PRICE, SET_CONFIRM_MODAL, SET_HAS_USER_REACHED_LIMIT,
         SET_TITLE, SET_AMAZON_IMAGE_URL, LOAD_LISTING, RESET_STATE, SET_IS_LOADING, SET_IS_URL_PARSED, SET_IS_RESPONSE_PENDING, SET_ASIN_ERROR, SET_SHIPPING_TEMPLATE, SET_AMAZON_ONLY_ERROR_MODAL } from '../actions/listerActions'

const initState = {
    asin: '',
    sku: '',
    shipping: '',
    isAsinValid: true,
    isUrlValid: false,
    isUrlParsed: false,
    isSKUValid: true,
    url: '',
    templates: [],
    showLoading: true,
    calculatedPrice: '',
    title: '',
    amazonImageURL: '',
    supplierItem: {
        id: '',
        price: '',
        imageUrl: '',
        availabilityStatus: false,
        variations: [],
        supplierItemId: '',
        url: 'url',
        name: 'name',
        type: 'ERROR',
        shipping: ''
    },
    template: {
        title: '---',
        supplierTaxPercent: 0,
        supplierShipping: 0,
        supplierDiscountPercent: 0,
        amazonFee: 0,
        amazonFeePercent: 15,
        profit: '10',
        profitIsPercent: true,
        changePrice: true,
        bundleQuantity: 1,
        changeQuantity: true,
        desiredQuantity: 3,
        disableOnOOS: true,
        minPriceMargin: 5,
        maxPriceMargin: 15,
        useCompetitiveRepricing: false,
        strategyId: null,
        handlingTime: 3,
        lowestPrice: '',
        isBuyBox: false,
        buyBoxPrice: '',
        minCompetitivePrice: 0,
        maxCompetitivePrice: 0
    },
    isFixForm: false,
    isLoading: false,
    confirmModal: false,
    isAsinLoading: false,
    isResponsePending: false,
    hasUserReachedLimit: false,
    asinError: null,
    alertType: 'error',
    alertEnabled: false,
    alertMessage: '',
    shippingTemplate: '',
    amazonOnlyErrorModal: false
}

const listerReducer = (state = initState, action) => {
    switch (action.type) {
        case LOAD_LISTING: {
            let template = {...initState.template}

            //Take template keys from payload (payload is an amazon listing)
            Object.keys(template).map(key => {
                if(template.hasOwnProperty(key)){
                    //whe know amazonListings have no title
                    if(action.payload[key] === undefined && key !== 'title'){
                        console.log(`Payload has no ${key}`);
                    }
                    template[key] = action.payload[key];
                }
                return true; //return to fix rect compile warning
            });

            template.title = '--'; //Template title

            let newState = {
                ...state,
                asin: action.payload.asin1,
                sku: action.payload.sellerSKU,
                title: action.payload.itemName,
                amazonImageURL: action.payload.imageURL,
                shipping: action.payload.shipping || '',
                handlingTime: 3,
                url: action.payload.SupplierItem.url,
                template: {
                    ...template
                },
                isUrlValid: true,
                isAsinValid: true,
                isFixForm: true
            }
            return newState;
        }
        case RESET_STATE:
            return {...initState}
        case SET_PRICE:
            if(isNaN(action.payload) || Number(action.payload) <= 0){
                action.payload = state.calculatedPrice;
            }
            return {
                ...state,
                calculatedPrice: action.payload
            }
        case SET_TEMPLATE:
            return {
                ...state,
                template: {
                    ...action.payload
                }
            }
        case SET_SHIPPING_TEMPLATE:
            return {
                ...state,
                shippingTemplate: action.payload
            }
        case SET_SUPPLIER_ITEM:
            return {
                ...state,
                supplierItem: {
                    ...action.payload
                }
            }
        case TOGGLE_LOADING:
            return {
                ...state,
                showLoading: !state.showLoading
            }
        case SET_ASIN:
            return {
                ...state,
                asin: action.payload
            }
        case SET_SKU:
            return {
                ...state,
                sku: action.payload
            }
        case SET_SHIPPING:
            if(isNaN(action.payload) || Number(action.payload) < 0){
                action.payload = state.shipping;
            }
            return {
                ...state,
                shipping: action.payload
            }
        case SET_IS_ASIN_VALID:
            return {
                ...state,
                isAsinValid: action.payload
            }
        case SET_IS_SKU_VALID:
            return {
                ...state,
                isSKUValid: action.payload
            }
        case SET_IS_URL_VALID:
            return {
                ...state,
                isUrlValid: action.payload
            }
        case SET_IS_URL_PARSED:
            return {
                ...state,
                isUrlParsed: action.payload
            }
        case SET_IS_LOADING:
            return {
                ...state,
                showLoading: action.payload
            }
        case SET_IS_RESPONSE_PENDING:
            return {
                ...state,
                isResponsePending: action.payload
            }
        case SET_URL:
            return {
                ...state,
                url: action.payload
            }
        case SET_TEMPLATES:
            return {
                ...state,
                templates: action.payload
            }
        case SET_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case SET_AMAZON_IMAGE_URL:
            return {
                ...state,
                amazonImageURL: action.payload
            }
        case SET_IS_ASIN_LOADING:
            return {
                ...state,
                isAsinLoading: action.payload
            }
        case SET_HAS_USER_REACHED_LIMIT:
            return {
                ...state,
                hasUserReachedLimit: action.payload
            }    
        case SET_CONFIRM_MODAL: 
            return {
                ...state,
                confirmModal: action.payload
            }
        case SET_ASIN_ERROR: 
            return {
                ...state,
                asinError: action.payload
            }
        case SET_ALERT:
            return {
                ...state,
                ...action.payload
            }
        case SET_AMAZON_ONLY_ERROR_MODAL:
            return {
                ...state,
                amazonOnlyErrorModal: action.payload
            }
        default:
            return {
                ...state
            }

    }
}

export default listerReducer;
