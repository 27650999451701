const initState = {
    plan: {
        originalPrice: 0,
        discountAmount: 0,
        discountedPrice: 0,
        discountPercent: 0,
        overchargeAmount: 0,
        discountIsPercent: false,
        isFirstMonthOffer: false
    },
    plans: {},
    planId: '',
    planModal: false,
    userPlanId: 0,
    userHasProductResearch: false,
    successModal: false,
    hasAmazonAccount: true,
    isPurchaseLoading: false,
    includeProductResearchTool: true,
    productResearchPrice: 29,
    subscriptionErrorMessage: '',
    successMessage: 'You have subscribed to a plan successfully!',
    proratedPrice: 0,
    isOrderPreview: false,
}

const plansReducer = (state = initState, action) => {
    return {
        ...state,
        ...action.payload
    }
}

export default plansReducer;
