const initState = {
    icon: 'AlertCircle',
    title: '',
    users: [],
    sendTo: 0,
    description: '',
    notifications: [],
    isPageLoading: true
}

const notificationsReducer = (state = initState, action) => {
    return {
        ...state,
        ...action.payload
    }
}

export default notificationsReducer;
