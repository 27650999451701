const initState = {
    ctx: false,
    isHidden: true,
    message: '',
    link: '',
    linkText: '',
    className: '',
    disableLister: false,
    hasActiveSubscription: false,
    useAmazonOnly: false,
}

const warningsReducer = (state = initState, action) =>({
    ...state,
    ...action.payload
})

export default warningsReducer;
