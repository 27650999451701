const initState = {
    strategies: [],
    strategy: {
        title: '',
        minPriceMargin: '',
        maxPriceMargin: '',
        buyBoxDistance: '',
        buyBoxDistanceIsPercent: false,
        isBuyBoxDistanceNegative: true,
        isMatchBuyBox: false,
        isReturnOverInvestment: false,
        minPriceMarginIsPercent: true,
        minPriceMarginIsROI: false,
        maxPriceMarginIsPercent: true,
        maxPriceMarginIsROI: false,
        sellerRating: '',
        strategyId: '',
        noCompStrategyId: '',
        listingsCount: 0,
        belowLowestStrategyId: '',
        stayInBuyBox: '',
        errFields: []
    },
    id: 0,
    index: 0,
    modal: false,
    modalTitle: '',
    deleteModal: false,
    deleteModalTitle: '',
    alertType: 'danger',
    alertEnabled: false,
    alertMessage: '',
    hasAmazonAccount: true,
    isLoading: false
}

const strategiesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'resetState':
            return {
                ...state,
                strategy: {
                    ...initState.strategy
                },
                id: 0,
                index: 0
            };
        case 'resetStateFormOnly':
            return {
                ...state,
                strategy: {
                    ...initState.strategy
                }
            };
        case 'setIsLoading':
            return  {
                ...state,
                isLoading: action.payload
            };
        case 'setStrategy':
            return  {
                ...state,
                strategy: {...state.strategy, ...action.payload}
            };
        case 'showModal':
            return {
                ...state,
                ...action.payload,
                modal: true
            }
        case 'setStrategies':
            return {
                ...state,
                ...action.payload
            }
        case 'hideModal':
            return {
                ...state,
                modal: false,
                id: 0,
                index: 0
            }
        case 'updateField':
            return {
                ...state,
                strategy: {
                    ...state.strategy,
                    [action.payload.field]: action.payload.value
                }
            }
        case 'updateAmazonAccountStatus':
            return {
                ...state,
                ...action.payload
            }
        case 'openDeleteModal':
            return {
                ...state,
                ...action.payload,
                deleteModal: true
            }
        case 'closeDeleteModal':
            return {
                ...state,
                ...action.payload,
                deleteModal: false,
                id: 0,
                index: 0
            }
        case 'deleteStrategy':
            return {
                ...state,
                ...action.payload
            }
        case 'openAlert':
            return {
                ...state,
                ...action.payload
            }
        case 'closeAlert':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default strategiesReducer;
