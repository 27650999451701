const DO_NOT_CHANGE = "";
const DISABLE = false;
const ENABLE = true;


const initState = {
    profitCbx: false,
    amazonFeePercentCbx: false,
    supplierShippingCbx: false,
    supplierTaxPercentCbx: false,
    supplierDiscountPercentCbx: false,
    bundleQuantityCbx: false,
    handlingTimeCbx: false,
    minHardPriceCbx: false,
    desiredQuantityCbx: false,
    maxSalesPer24HoursCbx: false,
    changeQuantityCbx: false,
    changePriceCbx: false,
    RepricingStrategyIdCbx: false,
    isScanningActiveCbx: false,
    profitToggle: 'margin',

    profit: DO_NOT_CHANGE,
    amazonFeePercent: DO_NOT_CHANGE,
    supplierShipping: DO_NOT_CHANGE,
    supplierTaxPercent: DO_NOT_CHANGE,
    supplierDiscountPercent: DO_NOT_CHANGE,
    bundleQuantity: DO_NOT_CHANGE,
    handlingTime: DO_NOT_CHANGE,
    minHardPrice: DO_NOT_CHANGE,
    minHardPriceIsPercent: true,
    desiredQuantity: DO_NOT_CHANGE,
    changePrice: DO_NOT_CHANGE,
    changeQuantity: DO_NOT_CHANGE,
    maxSalesPer24Hours: DO_NOT_CHANGE,
    isScanningActive: DO_NOT_CHANGE,
    disableOnOOS: DO_NOT_CHANGE,
    isReturnOverInvestment: DO_NOT_CHANGE,
    RepricingStrategyId: DO_NOT_CHANGE,
    isFormValid: true,
    showBulkEditModal: false,
    profitIsPercent: DO_NOT_CHANGE,
    listingIds: [],
    isBulkConfirmModal: false, //used for delete/unlink modals
    isSaveDisabled: false, //disables the save button on submit (waiting server response)
    warningMessage: '' //displays warning message if not empty
}

const bulkEditReducer = (state = initState, action) => {
    let newState = state;
    switch (action.type) {
        // on input type=select change (takes id and value)
        // '' => do not change
        // true => activate
        // false => deactivate
        case 'SELECT_CHANGE':
            if (newState.hasOwnProperty(action.payload.id)) {
                if(action.payload.value === DO_NOT_CHANGE){
                    newState[action.payload.id] = DO_NOT_CHANGE;
                } else {
                    newState[action.payload.id] = action.payload.value === "true" ? ENABLE : DISABLE;
                }
            }
            newState.isFormValid = validateForm(newState);
            return newState;
        case 'TEXT_CHANGE':
            if (newState.hasOwnProperty(action.payload.id)) {
                newState[action.payload.id] = action.payload.value
            }
            newState.isFormValid = validateForm(newState);
            console.log(newState);
            return newState;
        case 'ADD_LISTING':
            var idArrAdd = action.payload;
            var newIdArrAdd = [...newState.listingIds];
            for (var i = 0; i < idArrAdd.length; i++) {
                var listingIdToAdd = idArrAdd[i];
                var indexAdd = newIdArrAdd.indexOf(listingIdToAdd)
                if( indexAdd === -1){
                    newIdArrAdd.push(listingIdToAdd);
                }
            }
            newState.listingIds = newIdArrAdd;
            return newState;
        case 'REMOVE_LISTING':
            var idArrRemove = action.payload;
            var newIdArrRemove = [...newState.listingIds];
            for (var j = 0; j < idArrRemove.length; j++) {
                var listingIdToRemove = idArrRemove[j];
                var indexRemove = newIdArrRemove.indexOf(listingIdToRemove)
                if( indexRemove !== -1){
                    newIdArrRemove.splice(indexRemove, 1);
                }
            }
            newState.listingIds = newIdArrRemove;
            return newState;
        case 'TOGGLE_MODAL':
            let isOpen = action.payload;
            newState.showBulkEditModal = isOpen;
            return newState;
        case 'TOGGLE_CONFIRM_MODAL':
            let isConfirmModal = action.payload;
            newState.isBulkConfirmModal = isConfirmModal;
            return newState;
        case 'CLEAR_STATE':
            newState.profitCbx = false;
            newState.amazonFeePercentCbx = false;
            newState.supplierShippingCbx = false;
            newState.supplierTaxPercentCbx = false;
            newState.supplierDiscountPercentCbx = false;
            newState.bundleQuantityCbx = false;
            newState.handlingTimeCbx = false;
            newState.minHardPriceCbx = false;
            newState.desiredQuantityCbx = false;
            newState.changePriceCbx = false;
            newState.RepricingStrategyIdCbx = false;
            newState.isScanningActiveCbx = false;
            newState.changeQuantityCbx = false;
            newState.maxSalesPer24HoursCbx = false;
            newState.minHardPriceIsPercent = true;
            newState.profit = '';
            newState.profitToggle = 'margin';
            newState.amazonFeePercent = '';
            newState.supplierShipping = '';
            newState.supplierTaxPercent = '';
            newState.supplierDiscountPercent = '';
            newState.bundleQuantity = '';
            newState.handlingTime = '';
            newState.minHardPrice = '';
            newState.desiredQuantity = '';
            newState.changePrice = DO_NOT_CHANGE;
            newState.changeQuantity = DO_NOT_CHANGE;
            newState.isScanningActive = DO_NOT_CHANGE;
            newState.useCompetitiveRepricing= DO_NOT_CHANGE;
            newState.disableOnOOS = DO_NOT_CHANGE;
            newState.RepricingStrategyId = '';
            newState.isReturnOverInvestment = '';
            newState.isFormValid = true;
            newState.showBulkEditModal = false;
            newState.profitIsPercent = '';
            newState.maxSalesPer24Hours = DO_NOT_CHANGE;
            newState.isBulkConfirmModal= false; //used for delete/unlink modals
            return newState;
        case 'SET_BULK_WARNING_MESSAGE':
            let warningMessage = action.payload;
            newState.warningMessage = warningMessage;
            return newState;
        default:
            return newState;
    }
}

function validateForm(state){
    if(state.desiredQuantity === '' && state.changeQuantity === ENABLE){
        return false;
    }

    if(state.useCompetitiveRepricing === '' && (state.minPriceMargin === '' || state.maxPriceMargin === '' || state.buyBoxDistance === '' || state.RepricingStrategyId === '')){
        return true
    }  
    if(state.useCompetitiveRepricing === ENABLE && (state.minPriceMargin === '' || state.maxPriceMargin === '' || state.buyBoxDistance === '' || state.isBuyBoxDistanceNegative === '' || state.RepricingStrategyId === '')){
        return false;
    }
    if(state.useCompetitiveRepricing === DISABLE && (state.profit === '')){
        return false;
    }

    return true
}

export default bulkEditReducer;
