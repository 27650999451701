let endDate = new Date();
endDate.setDate(endDate.getDate() + 1);
var endDateString = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
let startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
var startDateString = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];



const initState = {
    items: [],
    ordersCount: null,
    options: {
        showImage: true,
        showDate: true,
        showItemTitle: true,
        showSupplier: true,
        showSupplierId: true,
        showSupplierOrderId: true,
        showOrderId: true,
        showTracker: true,
        showAsin: true,
        showBuyerAddress: true,
        showSupplierPrice: true,
        showSupplierShipping: true,
        showPrice: true,
        showAmazonFees: true,
        showAmazonShipping: true,
        showSupplierTax:true,
        showSupplierDiscount: true,
        showQuantitySold: true,
        showBundleQuantity: true,
        showProfit: true,
        showProfitMargin: true,
        showSKU: true,
        showNotesOrder: true,
        showLatestShipDate: true
    },
    isTitleHidden: [],
    areAllHiden: true,
    searchFilter: '',
    supplierFilter: '',
    orderStatusFilter: '',
    startDate: startDateString,
    endDate: endDateString,
    columnName: 'purchaseDate',
    isColumnAsc: false,
    resultFilter: 50,
    pagesCount: 1,
    pageNumber: 1,
    hasAmazonAccount: true,
    alertType: 'danger',
    alertEnabled: false,
    alertMessage: '',
    canUsePasteMe: false,
    pasteMePopUpOpen: false,
    timezone: 'America/Los_Angeles'
}

const amazonOrdersReducer = (state = initState, action) => {
    switch(action.type){
        case 'setOrdersCount':
            return {
                ...state,
                ordersCount: action.payload
            }
        case 'setItems':
            return {
                ...state,
                items: action.payload
            }
        case 'setOptions':
            return {
                ...state,
                options: {
                    ...state.options,
                    ...action.payload.options
                },
                areAllHiden: action.payload.areAllHiden,
                isTitleHidden: [
                    ...action.payload.isTitleHidden
                ],
                timezone: action.payload.timezone || state.timezone
            };
        case 'setFilter':
            return {
                ...state,
                [action.payload.filter]: [action.payload.value]
            }
        case 'setPages':
            return {
                ...state,
                pagesCount: action.payload.pagesCount,
                pageNumber: action.payload.pageNumber,
                columnName: action.payload.columnName,
                isColumnAsc: action.payload.isColumnAsc
            }
        case 'updateAmazonAccountStatus':
            return {
                ...state,
                ...action.payload
            }
        case 'openAlert':
            return {
                ...state,
                ...action.payload
            }
        case 'closeAlert':
            return {
                ...state,
                ...action.payload
            }
        case 'setCanUse': 
            return {
                ...state,
                ...action.payload
            }
        case 'togglePasteMePopUp': 
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default amazonOrdersReducer;
