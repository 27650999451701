const countries = require('../../utility/Countries');

const initState = {
    deleteModal: false,
    isCvvValid: false,
    isDateValid: false,
    isNumberValid: false,
    isCardFormValid: false,
    isCardSupported: false,
    isBillingFormValid: false,
    isCardFormConfirmed: false,
    clientToken: false,
    firstName: '',
    lastName: '',
    company: '',
    address: '',
    country: 'United States',
    city: '',
    vat: '',
    firstNameIsValid: true,
    lastNameIsValid: true,
    companyIsValid: true,
    addressIsValid: true,
    // countryIsValid: true,
    cityIsValid: true,
    // vatIsValid: true,
    card: {
        type: '',
        logo: '',
        token: false,
        digits: ''
    },
    countries,
    cardError: '',
    cardDigits: 3
}

const braintreeCardsReducer = (state = initState, action) => {
    return {
        ...state,
        ...action.payload
    }
}

export default braintreeCardsReducer;
