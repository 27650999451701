// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import internal(own) modules
import { unregister } from "./registerServiceWorker";
import { store } from "./web/redux/storeConfig/store";
import ReduxToastr from 'react-redux-toastr'

import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./web/components/spinner/spinner";
import ReactPixel from 'react-facebook-pixel';

const LazyApp = lazy(() => import("./web/app/app"));

ReactPixel.init('268271585892101', {}, { debug: false, autoConfig: false });
ReactPixel.pageView();

ReactDOM.render(
   <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick/>
      </Suspense>
   </Provider>,
   document.getElementById("root")
);
unregister();
