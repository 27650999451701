const initState = {
    proxies: [],
    parsers: [],
    isPageLoading: true
}

const proxiesReducer = (state = initState, action) => {
    return {
        ...state,
        ...action.payload
    }
}

export default proxiesReducer;
