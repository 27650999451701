const initState = {
    isValid: false,
    isLoading: false,
    variations: [],
    urlAddress: '',
    hasAmazonAccount: true
    // alertType: 'success',
    // alertEnabled: false,
    // alertMessage: ''
}

const variationsCheckerReducer = (state = initState, action) => {
    return {
        ...state,
        ...action.payload
    }
}

export default variationsCheckerReducer;
