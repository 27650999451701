const defaultTemplate = () => {
    return {
        title: '',
        supplierTaxPercent: '',
        supplierShipping: '',
        supplierDiscountPercent: '',
        amazonFee: '',
        amazonFeePercent: '',
        profit: '',
        profitIsPercent: false,
        changePrice: true,
        bundleQuantity: 1,
        changeQuantity: false,
        desiredQuantity: '',
        disableOnOOS: true,
        handlingTime: 3,
        minPriceMargin: 0,
        maxPriceMargin: 0,
        isCustomPriceMargin: false,
        useCompetitiveRepricing: false,
        minHardPrice: '',
        shippingTemplate: '',
    };
}

const initState = {
    index: 0,
    items: [],
    itemId: 0,
    template: new defaultTemplate(),
    templates: [],
    templateId: 0,
    alertType: 'danger',
    alertEnabled: false,
    alertMessage: '',
    editModal: false,
    editModalTitle: '',
    unlinkModal: false,
    unlinkModalTitle: '',
    deleteModal: false,
    deleteModalTitle: '',
    limitModal: false,
    scanningModal: false,
    exportToCsvModal: false,
    exportToCsvIsLoading: false,
    supplierURL: '',
    supplierType: 'ERROR',
    supplierTitle: '',
    supplierPrice: 0,
    supplierCurrency: '',
    supplierImageURL: '',
    supplierCalculatedPrice: '',
    supplierAvailabilityStatus: '',
    supplierVariants: [],
    supplierOffers: [],
    supplierId: '',
    supplierItemId: 0,
    supplierZipCode: '',
    asin1: 0,
    areAllHiden: true,
    pageNumber: 1,
    pagesCount: 0,
    columnName: 'id',
    isColumnAsc: false,
    searchFilter: '',
    supplierFilter: '',
    strategyFilter: '',
    statusFilter: '',
    resultFilter: '50',
    listingsCount: 0,
    activeListings: 0,
    inActiveListings: 0,
    showItemPicture: true,
    showItemTitle: true,
    showOpenDate: true,
    showSupplier: true,
    showSupplierId: true,
    showSupplierPrice: true,
    showSupplierShipping: true,
    showSupplierQuantity: false,
    showAsin: true,
    showSku: true,
    showPrice: true,
    showQuantity: true,
    showProfit: true,
    showStatus: true,
    showStatusAmazon: true,
    showScanning: true,
    showAction: true,
    showNotes: false,
    showMinStrategyPrice: true,
    showMaxStrategyPrice: true,
    showHandlingTime: false,
    showLowestPrice: true,
    showBuyBoxPrice: true,
    showBuyBoxEligible: true,
    showSalesInLast24Hours: false,
    showBundleQuantity: true,
    showOrderCount: true,
    isUserActive: true,
    isContentLoading: false,
    isEditModalMinimized: false,
    hasAmazonAccount: true,
    hasPolledAmazonListings: true,
    isLostAccess: false,
    historyModal: false,
    pageHandlers:[],
    showDeleteSuccess: false,
    isAmazonDelete: true,
    userNotes: '',
    isResponsePending: false,
    templateModal: false,
    strategyModal: false,
    feedErrors: null,
    isInScanning: false,
    timezone: 'America/Los_Angeles',
    amazonOnlyErrorModal: false,
}

const amazonListingsReducer = (state = initState, action) => {
    return {
        ...state,
        ...action.payload
    }
}

export default amazonListingsReducer;
