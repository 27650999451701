// import external modules
import { combineReducers } from "redux";
import customizer from "./customizer/";
import loginReducer from "./loginReducer";
import profileReducer from "./profileReducer";
import amazonListingsReducer from "./amazonListingsReducer";
import policiesReducer from './policiesReducer';
import bulkUploadReducer from "./bulkUploadReducer";
import variationsCheckerReducer from "./variationsCheckerReducer";
import amazonOrdersReducer from "./amazonOrdersReducer";
import notificationsReducer from "./notificationsReducer";
import proxiesReducer from "./proxiesReducer";
import braintreeCardsReducer from "./braintreeCardsReducer";
import plansReducer from "./plansReducer";
import warningsReducer from "./warningsReducer";
import listerReducer from "./listerReducer";
import bulkEditReducer from "./bulkEditReducer";
import bulkListReducer from "./bulkListReducer";
import bulkLinkReducer from "./bulkLinkReducer";
import templatesReducer from "./templatesReducer";
import strategiesReducer from './strategiesReducer';

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   toastr: toastrReducer, // <- Mounted at toastr.
   customizer: customizer,
   loginReducer: loginReducer,
   profileReducer: profileReducer,
   amazonListingsReducer: amazonListingsReducer,
   policiesReducer: policiesReducer,
   bulkUploadReducer: bulkUploadReducer,
   amazonOrdersReducer: amazonOrdersReducer,
   variationsCheckerReducer: variationsCheckerReducer,
   notificationsReducer: notificationsReducer,
   proxiesReducer: proxiesReducer,
   braintreeCardsReducer: braintreeCardsReducer,
   plansReducer: plansReducer,
   warningsReducer: warningsReducer,
   listerReducer: listerReducer,
   bulkEditReducer: bulkEditReducer,
   bulkListReducer: bulkListReducer,
   bulkLinkReducer: bulkLinkReducer,
   templatesReducer: templatesReducer,
   strategiesReducer: strategiesReducer
});

export default rootReducer;
