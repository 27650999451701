export const SET_ASIN = 'SET_ASIN';
export const SET_SKU = 'SET_SKU';
export const SET_SHIPPING = 'SET_SHIPPING';
export const SET_IS_ASIN_VALID = 'SET_IS_ASIN_VALID';
export const SET_IS_URL_VALID = 'SET_IS_URL_VALID';
export const SET_IS_ASIN_LOADING = 'SET_IS_ASIN_LOADING';
export const SET_URL = 'SET_URL';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_CONFIRM_MODAL = 'SET_CONFIRM_MODAL';
export const SET_SUPPLIER_ITEM = 'SET_SUPPLIER_ITEM';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_PRICE = 'SET_PRICE';
export const SET_TITLE = 'SET_TITLE';
export const SET_AMAZON_IMAGE_URL = 'SET_AMAZON_IMAGE_URL';
export const LOAD_LISTING = 'LOAD_LISTING';
export const RESET_STATE = 'RESET_STATE';
export const SET_IS_SKU_VALID = 'SET_IS_SKU_VALID';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_URL_PARSED = 'SET_IS_URL_PARSED';
export const SET_IS_RESPONSE_PENDING = 'SET_IS_RESPONSE_PENDING';
export const SET_HAS_USER_REACHED_LIMIT = 'SET_HAS_USER_REACHED_LIMIT';
export const SET_ASIN_ERROR = 'SET_ASIN_ERROR';
export const SET_ALERT = 'SET_ALERT';
export const SET_SHIPPING_TEMPLATE = 'SET_SHIPPING_TEMPLATE';
export const SET_AMAZON_ONLY_ERROR_MODAL = 'SET_AMAZON_ONLY_ERROR_MODAL';

export const resetState = () => ({
    type: RESET_STATE,
    payload: null
})

export const loadListing = (listing) => ({
    type: LOAD_LISTING,
    payload: listing
})

export const setAmazonImageURL = (url) => ({
    type: SET_AMAZON_IMAGE_URL,
    payload: url
})

export const setTitle = (title) => ({
    type: SET_TITLE,
    payload: title
})

export const setPrice = (price) => ({
    type: SET_PRICE,
    payload: price
})

export const setTemplate = (template) => ({
    type: SET_TEMPLATE,
    payload: template
})

export const setSupplierItem = (supplierItem) => ({
    type: SET_SUPPLIER_ITEM,
    payload: supplierItem
})

export const toggleLoading = () => ({
    type: TOGGLE_LOADING
})

export const setAsin = (asin) => ({
    type: SET_ASIN,
    payload: asin
})

export const setSKU = (sku) => ({
    type: SET_SKU,
    payload: sku
})

export const setShipping = (shipping) => ({
    type: SET_SHIPPING,
    payload: shipping
})

export const setShippingTemplate = (shipping) => ({
    type: SET_SHIPPING_TEMPLATE,
    payload: shipping
})

export const setIsAsinValid = (isValid) => ({
    type: SET_IS_ASIN_VALID,
    payload: isValid
})

export const setIsAsinLoading = (isAsinLoading) => ({
    type: SET_IS_ASIN_LOADING,
    payload: isAsinLoading
})

export const setIsUrlValid = (isValid) => ({
    type: SET_IS_URL_VALID,
    payload: isValid
})

export const setIsUrlParsed = (isValid) => ({
    type: SET_IS_URL_PARSED,
    payload: isValid
})

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: isLoading
})

export const setIsSKUValid = (isValid) => ({
    type: SET_IS_SKU_VALID,
    payload: isValid
})

export const setUrl = (url) => ({
    type: SET_URL,
    payload: url
})

export const setTemplates = (templates) => ({
    type: SET_TEMPLATES,
    payload: templates
});

export const setConfirmModal = (confirmModal) => ({
    type: SET_CONFIRM_MODAL,
    payload: confirmModal
});

export const setIsResponsePending = (data) => ({
        type: SET_IS_RESPONSE_PENDING,
        payload: data
});

export const setHasUserReachedLimit = (data) => ({
    type: SET_HAS_USER_REACHED_LIMIT,
    payload: data
});

export const setAsinError = (data) => ({
    type: SET_ASIN_ERROR,
    payload: data
});


export const setAlert = (data) => ({
    type: SET_ALERT,
    payload: data
});

export const setAmazonOnlyErrorModal = (data) => ({
    type: SET_AMAZON_ONLY_ERROR_MODAL,
    payload: data
});