const initState = {
    policies: [],
    policyId: 0,
    modalTitle: '',
    modalEnabled: false,
    modalMessage: '',
    modalHasPassed: false,
    isProcessing: false,
    uploadHistory: {},
    uploadFileName: 'NO FILE CHOSEN',
    hasAmazonAccount: true
}

const bulkUploadReducer = (state = initState, action) =>{
    return {
        ...state,
        ...action.payload
    }
}

export default bulkUploadReducer;
