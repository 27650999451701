const initState = {
    errors: [],
    listings: [],
    isLoading: false,
    fileName: 'Browse...',
    confirmModal: false,
    successModal: false
}

const bulkLinkReducer = (state = initState, action) => {
    let newState = state;
    switch (action.type) {
        case 'UPDATE_ERRORS':
            newState.errors = [...action.payload];
            return newState;
        case 'UPDATE_LISTINGS':
            newState.listings = [...action.payload];
            return newState;
        case 'CHANGE_FILE_NAME':
            newState.fileName = action.payload;
            return newState;
        case 'IS_LOADING_TOGGLE':
            newState.isLoading = action.payload;
            return newState;    
        case 'CONFIRM_MODAL_TOGGLE':
            newState.confirmModal = action.payload;
            return newState;
        case 'SUCCESS_MODAL_TOGGLE':
            newState.successModal = action.payload;
            return newState;
        default:
            return newState;
    }
}

export default bulkLinkReducer;
