const initState = {
    name: '',
    email: '',
    password: '',
    passwordRepeat: '',
    howDidYouHearAboutUs: '',
    captchaValue: '',
}

const loginReducer = (state = initState, action) =>({
    ...state,
    ...action.payload
})

export default loginReducer;