const initState = {
    accountName: '',
    accountEmail: '',
    accountSubscription: '',
    planName: '',
    planLimit: 0,
    planListings: '',
    billingType: '',
    billingLastDigits: '',
    billingExpireDate: '',
    billingLastPaymentDate: '',
    lastPaymentDate: '',
    hasAmazonAccount: true,
    amazonName: '',
    amazonSellerId: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    toggleAddModal: false,
    togglePaymentHistoryModal: false,
    payments: [],
    deleteAmazonModal: false,
    cancelSubscriptionModal: false,
    isSaveBillingInfo: false,
    changePrice: false,
    isDeletePending: false,
    marketplaceId: '',
    marketplacesArr: [],
    useRestockEbay: false,
    restockEbayPercent: 100,
    isRestockEbayActive: false,
    minEbayQuantity: null,
    useMinEbayQuantity: false,
    apiKey: false,
    timezone: 'America/Los_Angeles',
    showAddAmazonPopup: false,
    showOnboardingPopup: false,
    cancelProdResearchModal: false,
    cancelProdResearchModalSuccess: false,
    hasProductResearchTool: false,
    hasActiveProductResearchSubscription: false,
    addProductResearchError: '',
    isAddProductResearchOpen: false,
    productResearchPaidUntil: '',
    paidUntil: '',
    addUsedAmazonAccountModal: false,
    showSetupGuideModal: false
}

const profileReducer = (state = initState, action) =>({
    ...state,
    ...action.payload
})

export default profileReducer;
