const initState = {
    policy: {
        title: 'New policy default',
        supplierTaxPercent: 0,
        supplierShipping: 0,
        supplierDiscountPercent: 0,
        amazonFee: '0.00',
        amazonFeePercent: '15.00',
        profit: '15.00',
        profitIsPercent: true,
        changePrice: true,
        bundleQuantity: 1,
        changeQuantity: true,
        desiredQuantity: 3,
        disableOnOOS: true,
        handlingTime: 3,
        useCompetitiveRepricing: false,
        minPriceMargin: '5.00',
        maxPriceMargin: '15.00',
        isCustomPriceMargin: false,
        checked: false,
        minHardPrice: '0.00',
        buyBoxDistance: '0.01',
        buyBoxDistanceIsPercent: false,
        isBuyBoxDistanceNegative: true,
        buyBoxPrice: '',
        lowestPrice: '',
        minCompetitivePrice: '',
        maxCompetitivePrice: '',
        calculatedPrice: '0.01',
        isMatchBuyBox: false,
        isReturnOverInvestment: false,
        minPriceMarginIsPercent: true,
        minPriceMarginIsROI: false,
        maxPriceMarginIsPercent: true,
        maxPriceMarginIsROI: false,
        isMinMaxIntervalValid: true,
        competitionPrice: false, //price to compete with when dynamic peprice is on
        hasOffers: false,
        strategyId: 1,
        sellerRating: 0
    },
    supplierItemData: {
        id: 0,
        price: '',
        type: 'ERROR',
        availabilityStatus: false,
        url: '',
        imageURL: '',
        variants: []
    },
    id: 0,
    index: 0,
    policies: [],
    modal: false,
    modalTitle: '',
    deleteModal: false,
    deleteModalTitle: '',
    alertType: 'danger',
    alertEnabled: false,
    alertMessage: '',
    hasAmazonAccount: true
}

const policiesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'resetState':
            return {
                ...state,
                policy: {
                    ...initState.policy
                },
                supplierItemData: {
                    ...initState.supplierItemData
                },
                id: 0,
                index: 0
            };
        case 'setSupplierItem':
            return {
                ...state,
                supplierItemData: {
                    ...state.supplierItemData,
                    ...action.payload
                }
            }
        case 'setPolicy':
            return  {
                ...state,
                policy: {...state.policy, ...action.payload}
            };
        case 'showModal':
            return {
                ...state,
                ...action.payload,
                modal: true
            }
        case 'setPolicies':
            return {
                ...state,
                ...action.payload
            }
        case 'hideModal':
            return {
                ...state,
                modal: false,
                id: 0,
                index: 0
            }
        case 'updateField':
            return {
                ...state,
                policy: {
                    ...state.policy,
                    [action.payload.field]: action.payload.value
                }
            }
        case 'updateAmazonAccountStatus':
            return {
                ...state,
                ...action.payload
            }
        case 'openDeleteModal':
            return {
                ...state,
                ...action.payload,
                deleteModal: true
            }
        case 'closeDeleteModal':
            return {
                ...state,
                ...action.payload,
                deleteModal: false,
                id: 0,
                index: 0
            }
        case 'deletePolicy':
            return {
                ...state,
                ...action.payload
            }
        case 'openAlert':
            return {
                ...state,
                ...action.payload
            }
        case 'closeAlert':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default policiesReducer;
